/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)

require('../bootstrap5/dist/css/bootstrap.css');
require('../../node_modules/bootstrap-icons/font/bootstrap-icons.css');
require('../utils/css/bootstrap-datepicker.css');
require('../nutrinet/css/layout.css');
require('../nutrinet/css/layout_intermedaires.css');
require('../nutrinet/css/layout_tablette.css');
require('../nutrinet/css/layout_smartphone.css');
// require('../utils/css/timeout-dialog.css');
require('../nutrinet/css/fonts_google.css');
// require('../pub/css/questionnaire_pub.css');
require('../nutrinet/css/resultat.css');
require('../nutrinet/css/politique_layout.css');
require('../nutrinet/css/mes_questionnaires.css');
require('../nutrinet/css/menu_layout.css');
require('../nutrinet/css/menu_tablette_layout.css');
require('../nutrinet/css/menu_smartphone_layout.css');
require('../nutrinet/css/animation_accroche_layout.css');

//police roboto
//require('../nutrinet/polices/Roboto-Medium.ttf');
//require('../nutrinet/polices/Roboto-Regular.ttf');
//require('../nutrinet/polices/Roboto-Light.ttf');
//require('../nutrinet/polices/Roboto-Bold.ttf');




//police open sans
//require('../nutrinet/polices/opensans-lightitalic-webfont.ttf');
//require('../nutrinet/polices/opensans-lightitalic-webfont.woff');
//require('../nutrinet/polices/opensans-lightitalic-webfont.woff2');
//require('../nutrinet/polices/OpenSans-LightItalic.ttf');
//require('../nutrinet/polices/opensans-regular-webfont.woff');
//require('../nutrinet/polices/opensans-regular-webfont.woff2');
//require('../nutrinet/polices/OpenSans-Regular.ttf');
//require('../nutrinet/polices/opensans-semibold.woff');
//require('../nutrinet/polices/opensans-semibold.woff2');
//require('../nutrinet/polices/OpenSans-Semibold.ttf');


global.bootstrap = require('../bootstrap5/dist/js/bootstrap');
// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
global.$ = global.jQuery = $;
global.qrcode = require ('html5-qrcode');


